@import '@/styles/variables';

.salty {
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: $z-index-card-salt;
  display: flex;
  align-items: center;
  width: 26px;
  height: 26px;
  background: rgba($gray-800, 0.8);
  border-radius: 5px;

  img {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    filter: invert(100%);
  }

  &:hover .saltyTooltip {
    visibility: visible;
  }
}

.saltyTooltip {
  position: absolute;
  left: 0;
  display: flex;
  visibility: hidden; // note: iOS devices use SF Pro which is wider
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 244px;
  height: 26px;
  font-size: 13px;
  color: $white;
  background: $gray-800;
  border-radius: 5px;

  div {
    flex: 1;
    color: $white;
    text-align: center;
  }
}
