@import '@/styles/variables';

$twitch-z-index: 10000;

.container {
  position: fixed;
  right: 0;
  bottom: 70px; // Extra space for mediavine "high impact" ads
  z-index: $twitch-z-index;
  width: 160px;
  height: 120px; // 160x(90+30) with +30px height for their controls

  @include media-breakpoint-up(md) {
    width: 300px;
    height: 199px;
  }

  @include media-breakpoint-up(lg) {
    bottom: 0;

    &.hasDesktopAdhesion {
      bottom: 90px;
    }
  }
}

.close {
  position: absolute;
  top: -2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;

  &:hover {
    cursor: pointer;
  }

  path {
    fill: white;
  }
}
