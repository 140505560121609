@import '@/styles/variables';

.body {
  margin: 15px;
}

.card {
  height: 100%;
  padding: 0;
  margin: 0;
}

.container {
  height: 100%;
}

.date {
  font-size: 0.75rem;
  line-height: 1.5;
  text-transform: uppercase;
}

.heading {
  a {
    color: $headings-color;
  }

  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.youtube {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; // 16:9

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem 0.25rem 0 0;
  }
}
