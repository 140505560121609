@import '@/styles/variables';

.alternateArtButton {
  top: 75px !important;

  @include media-breakpoint-up(md) {
    top: 50px !important;
  }
}

$margin-top: 100px;

.card {
  height: 340px;
  border-radius: 4.25%;
}

.cardContainer:hover {
  cursor: pointer;
  background: rgb(0 0 0 / 10%);
  border-radius: 4.25%;
  filter: brightness(1.1);
}

.cards {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  max-height: calc(100vh - 2 * $margin-top - 1.75rem);
  margin-bottom: 1rem;
  overflow-y: auto;
}

.dialog {
  max-width: 400px;
  margin: $margin-top auto;

  @include media-breakpoint-up(md) {
    max-width: calc(100vw - 4rem);
  }

  :global(.modal-content) {
    margin: $margin-top auto;
  }
}

.set {
  font-size: 14px;
}
