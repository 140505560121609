@import '@/styles/variables';

.author {
  font-size: 12px;

  a {
    color: $orange !important;
  }
}

.avatar {
  position: absolute;
  top: 90px;
  right: 1rem;
  float: right;
  background-color: $gray-200;
  border: 2px solid $white;
  border-radius: 50%;

  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  [data-bs-theme='dark'] & {
    background-color: $gray-800;
    border-color: $gray-900;
  }

  @include media-breakpoint-up(md) {
    top: 135px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.container {
  flex: 1;
  width: 100%;
  height: 100%;
}

.date {
  font-size: 12px;
  text-transform: uppercase;
}

.small {
  .author,
  .date {
    font-size: 12px;
  }

  .avatar {
    top: 20px;
  }

  .excerpt {
    max-height: 36px;
    overflow: hidden;
    font-size: 12px;
  }

  .media {
    height: 80px;
  }

  .title {
    max-height: 40px;
    overflow: hidden;
    font-size: 16px;
  }

  :global(.card-body) {
    padding: 0.5rem;
  }
}

.media {
  height: 144px;
  object-fit: cover;
  object-position: top;
  background-color: var(--bs-secondary-bg);

  @include media-breakpoint-up(md) {
    height: 192px;
  }
}

.tags {
  max-height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;

  a {
    text-transform: lowercase;
  }
}

.title a {
  color: $headings-color;

  &:hover {
    text-decoration: underline;
  }
}
