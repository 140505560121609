@import '@/styles/variables';

.back {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $z-index-card-back;
  height: 306px;

  &.selected {
    z-index: $z-index-card-back-hover;
  }

  @include media-breakpoint-up(sm) {
    &:hover {
      z-index: $z-index-card-back-hover;
    }
  }
}

.border {
  border-radius: 4.25%;
  transition: transform 0.2s ease;
}

.regularImage {
  width: 100%;
  aspect-ratio: 244/340;
}

.doubleImage {
  width: 90%;
  aspect-ratio: 244/340;
}

.container {
  position: relative;
  width: 244px;
  aspect-ratio: 244/340;
}

.scalable {
  width: 100%;
}

.front {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: $z-index-card-front;
  height: 306px;

  @include media-breakpoint-up(sm) {
    &:hover {
      z-index: $z-index-card-front-hover;
    }
  }
}

.left {
  width: 244px;
  height: 340px;
}

.right {
  position: absolute;
  top: calc(340px + 1rem);
  left: 0;
  width: 244px;
  height: 340px;

  @include media-breakpoint-up(md) {
    top: 0;
    left: calc(244px + 1rem);
  }
}

.rotate90 {
  transform: rotate(90deg);
}

.sideBySide {
  height: calc(2 * 340px + 1rem);

  @include media-breakpoint-up(md) {
    width: calc(2 * 244px + 1rem);
    height: unset;
  }
}
