@import '@/styles/variables';

.spinner {
  font-size: 40px;
}

.spinnerContainer {
  position: absolute;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 244/340;
  background: var(--bs-tertiary-bg);
  border-radius: 4.25%;
}
