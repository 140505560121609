@import '@/styles/variables';

.close {
  position: absolute;
  bottom: 0;
}

.error {
  text-decoration: underline dotted red;
}

.hover {
  position: absolute;
  z-index: $z-index-card-hover;
}

.left {
  @include media-breakpoint-up(lg) {
    left: calc(1rem - 244px);
  }
}

.mobileViewer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-card-name;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb($dark, 0.75);
}

.sideBySide {
  width: 244px;
  height: calc(2 * 340px + 1rem);

  @include media-breakpoint-up(md) {
    width: calc(2 * 244px + 1rem + 2px);
    height: unset;
  }
}

.typo {
  text-decoration: underline dotted orange;
}
