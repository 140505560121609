@import '@/styles/variables';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-top: 1rem;

  @media screen and (width >= 728px) {
    height: 90px;
  }
}

// Old container that maintains 90x728 aspect ratio on mobile
.oldContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: min(90px, calc(100% * 90 / (728 + 2 * 16)));
  margin-top: 1rem;
}

.leaderboard {
  position: absolute;
  top: 0;
  height: 100%;
  margin: 0 1rem;

  img {
    max-width: 100%;

    /* @media screen and (min-width: 728px) {
      display: none;
    } */
  }
}

.mvMobile {
  max-height: 50px;

  @media screen and (width >= 728px) {
    display: none;
    height: 0;
  }
}
