@import '@/styles/variables';

$background-clip: 6px;
$background-height: 400px;

.backgroundContainer {
  position: absolute;
  top: 46px;
  width: 100%;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    top: 80px;
  }
}

.background {
  position: relative;
  left: -5px;
  width: calc(100% + 10px);
  padding-bottom: 70%;
  background-position: center;
  background-size: cover;
  filter: blur(4px);
}

.left {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: calc(100% - 300px);
  }
}

.main {
  flex-grow: 1;
  padding-top: 56px;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }
}

.pleaseSupportUs {
  margin: 0 1rem 1rem;
}

.sidebar {
  display: none;
  flex: 0 0 300px;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}
