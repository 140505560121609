@import '@/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;

  @include media-breakpoint-up(md) {
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.containerLeft {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1080px;
}

.containerRight {
  flex: 0 0 300px;
  width: 300px;
}

.dailyCommander {
  display: flex;
  justify-content: center;
}

.grid {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
  gap: 1rem;

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.links {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: center;

  .rowItem {
    flex: 1;

    .rowContent {
      margin: 0.5rem 0;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }
    }
  }
}

.video {
  width: calc(100vw - 2rem - 2px);
  margin: 0.5rem calc(0.25rem + 0.5px);
  margin-bottom: 1rem;

  > div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}
