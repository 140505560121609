@import '@/styles/variables';

.dropdown {
  :global(.dropdown-header),
  :global(.dropdown-item) {
    font-size: 0.875rem;
  }

  :global(.dropdown-item) {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

.filterText {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
