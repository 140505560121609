@import '~bootstrap/scss/functions';

// Bootstrap overrides
$white: #fff;
$gray-100: #f9fafb;
$gray-200: #eaedf0;
$gray-300: #dfe3e7;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6d767e;
$gray-700: #484f56;
$gray-800: #353b41;
$gray-900: #22262a;
$gray-950: #121315;
$black: #000;
$blue: #1763b5;
$indigo: #5820b1;
$purple: #5c3f92;
$pink: #c53075;
$red: #ad3440;
$orange: #c5681b;
$yellow: #b99118;
$green: #2a743b;
$teal: #288f70;
$cyan: #1f727f;
$light: $gray-200;
$dark: $gray-900;
$archidekt-orange: #fa890d;
$cardsphere-blue: #34495e;
$csb-pink: #db8ed3;
$edhrec-yellow: #ffe600;
$moxfield-purple: #b574d9;
$mtgstocks-gray: #444;
$scryfall-purple: #786076;
$enable-smooth-scroll: false; // CardLists don't scroll far enough with this enabled (11/10/2023)
$spacer: 1rem;
$link-decoration: none;
$headings-margin-bottom: 0;
$navbar-padding-x: calc($spacer / 2);
$alert-margin-bottom: 0;
$modal-inner-padding: $spacer $spacer 0 $spacer;
$modal-header-padding-y: $spacer;
$modal-header-padding-x: $spacer;
$dropdown-header-padding-y: $spacer * 0.25;
$breadcrumb-margin-bottom: 0;
$breadcrumb-divider: '';
$pagination-disabled-bg: var(--bs-body-bg);
$tooltip-max-width: 400px;
$tooltip-color: $gray-100;
$tooltip-bg: $black;

// Custom colors
$link-color-visited: #551a8b;
$link-color-visited-dark: #8540c5;

// Custom z-indexes
$z-index-tooltip: 1;
$z-index-banned-stamp: 5;
$z-index-card-back: 6;
$z-index-card-front: 7;
$z-index-new-stamp: 7;
$z-index-card-back-hover: 8;
$z-index-card-front-hover: 9;
$z-index-card-button: 10;
$z-index-toast: 100;
$z-index-card-name: 1000;
$z-index-card-salt: 1001;
$z-index-nav: 1002;
$z-index-navbar-2: 1030;
$z-index-navbar: 1031;
$z-index-clipboard: 1131;
$z-index-navbar-dropdown: 1133;
$z-index-navbar-dropdown-submenu: 1134;
$z-index-card-hover: 4001;
$z-index-scroll-to-top: 9999;
$z-index-max: 2147483647;

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/mixins/breakpoints';
