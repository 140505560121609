@import '@/styles/variables';

.banned {
  position: absolute;
  z-index: $z-index-banned-stamp;
  display: flex;
  justify-content: center;
  width: 223px;

  img {
    margin-top: 74px;
    transform: rotate(-30deg);
  }
}

.cardButton {
  display: block;
  aspect-ratio: 1;

  @include media-breakpoint-up(md) {
    display: none;
    width: 30px;
    height: 30px;
  }

  .cardButtonInner {
    position: absolute;
    z-index: $z-index-card-button;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 32px;
    color: $white;
    cursor: pointer;
    background-color: rgba($gray-800, 0.8);
    border-radius: 5px;

    @include media-breakpoint-up(md) {
      width: 30px;
      height: 30px;
      font-size: 16px;
    }
  }
}

.scalableCardButton {
  .cardButtonInner {
    width: 100%;
    height: unset;
    aspect-ratio: 1;
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
      width: 30px;
      height: 30px;
      font-size: 16px;
    }
  }
}

.cardButtons {
  position: absolute;
  top: 35px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 75px;
  width: 60px;

  :global(.dropdown-menu.show) {
    transform: translate(0, 59px) !important;
  }

  @include media-breakpoint-up(md) {
    top: 20px;
    gap: 10px;
    width: 30px;

    :global(.dropdown-menu.show) {
      transform: translate(0, 29px) !important;
    }
  }
}

.scalableCardButtons {
  gap: 0.5rem;
  width: 20%;

  @include media-breakpoint-up(md) {
    top: 20px;
    gap: 10px;
    width: 30px;
  }
}

.cardImageLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.error {
  text-decoration: underline dotted red;
}

.container {
  position: relative;
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  align-items: center;
  width: 244px;
  min-height: 340px;

  &:hover {
    .cardButton {
      display: flex;
    }

    .nameWrapper .name {
      color: $gray-100 !important;
      background: $gray-800;
    }
  }

  :global(.lazyload-wrapper) {
    width: 244px;
    height: calc(340px + 19.19px);
  }

  &.sideBySide {
    @include media-breakpoint-up(md) {
      width: calc(2 * 244px + 1rem);
    }
  }

  .sideBySide {
    position: relative;
    height: calc(2 * 340px + 1rem + 19.19px);

    @include media-breakpoint-up(md) {
      width: calc(2 * 244px + 1rem);
      height: unset;
    }
  }
}

.scalable {
  width: 100%;
  min-height: unset;
  aspect-ratio: 244/340;

  :global(.lazyload-wrapper) {
    width: 100%;
    height: unset;
    aspect-ratio: 244/340;
  }
}

.gameChanger {
  position: absolute;
  top: 0;
  z-index: $z-index-card-button;
  float: left;
  width: 0;
  height: 0;
  border-color: $orange transparent transparent $orange;
  border-style: solid;
  border-width: 1.3rem;
  border-radius: 12.5px 0 0;

  span {
    position: absolute;
    top: -17px;
    left: -17px;
    display: float;
    font-size: 14px;
    font-weight: 700;
    color: $gray-100;
    transform: rotate(-45deg);
  }

  &:hover {
    border-color: lighten($orange, 10%) transparent transparent lighten($orange, 10%);
    transition: $transition-base;
  }
}

.label {
  width: 244px;
  text-align: center;
  white-space: pre-line;
}

.name {
  position: relative;
  bottom: 48px;
  z-index: $z-index-card-name;
  max-width: 140px;
  padding: 0.25rem;
  margin-bottom: -64px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: $gray-100;
  white-space: nowrap;
  background: $gray-800;
  border-radius: 5px;

  @include media-breakpoint-up(sm) {
    background: transparent;
  }
}

.nameWrapper {
  position: absolute;
  top: 345px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-up(sm) {
    span {
      color: transparent !important;
    }
  }
}

.scalableNameWrapper {
  top: 0;
  align-items: flex-end;
  width: 100%;
  aspect-ratio: 244/340;
  padding-bottom: 1rem;
  pointer-events: none;
}

.nameUnderCard {
  min-height: 19px;
  overflow: hidden;
  font-size: 0.8em;
  text-align: center;
}

.new {
  position: absolute;
  top: 0;
  z-index: $z-index-card-button;
  float: left;
  width: 0;
  height: 0;
  border-color: $gray-800 transparent transparent $gray-800;
  border-style: solid;
  border-width: 1.3rem;
  border-radius: 12.5px 0 0;

  span {
    position: absolute;
    top: -13px;
    left: -19px;
    display: float;
    font-size: 11px;
    font-weight: 700;
    color: $edhrec-yellow;
    transform: rotate(-45deg);
  }
}
