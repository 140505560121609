@import '@/styles/variables';

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}

.prices {
  display: flex;
  justify-content: space-around;
  min-height: 19px;
  font-size: 0.8rem !important;
  font-style: normal;

  > * {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  svg {
    height: 14px;
    margin-right: 2px;
    fill: $primary;
  }
}

.scalable {
  flex-wrap: wrap;
}

.sale {
  font-weight: bold;
  color: $red !important;

  svg {
    fill: $red !important;
  }
}
