@import '@/styles/variables';

.label {
  width: 244px;
  text-align: center;
  white-space: pre-line;
}

.synergy {
  position: relative;
  display: inline-block;

  @include media-breakpoint-up(sm) {
    border-bottom: 1px dashed $gray-500;
  }
}

.synergyTooltip {
  position: absolute;
  z-index: $z-index-tooltip;
  display: none;
  visibility: hidden;
  font-size: 14px;
  color: $white;
  background-color: $black;
  border-radius: 5px;

  @include media-breakpoint-up(sm) {
    display: inline;
  }
}

.synergy:hover .synergyTooltip {
  visibility: visible;
}

.synergyTooltipBody {
  display: inline-flex;
  align-items: center;
  min-width: auto;
  margin: 2.5px 7.5px;

  span {
    margin: 2.5px;
    white-space: nowrap;
  }
}
